<template>
  <div class="main-container" dir="ltr">
    <div class="row" dir="rtl">
      <div class="col-xl-6 col-md-6">
        <div class="my-account-content2" dir="rtl" style="background: linear-gradient(0deg,#004d4d 0,#03acac );">
          <div class="header-profile-login">
            <div class="row justify-content-between">
              <!-- <div class="col-8 col-sm-8 col-lg-8 col-xl-8">
                                <h6 class="text-center">الغاء الاشتراك</h6>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4 col-xl-4" style="padding-right: 0;">
                                <img style="width:130px;float:left;" class="logo" src="../../assets/images/logoSvg.svg" />
                            </div> -->
              <div class="col-8 col-sm-8 col-lg-8 col-xl-8">
                <h6 class="text-center" style="color: #ffffff;font-size: 20px;">الغاء الاشتراك</h6>
              </div>
              <div class="col-4 col-sm-4 col-lg-4 col-xl-4" style="margin-left: 60px;">
                <img style="width:130px;float:left;" class="logo" src="../../assets/images/logoSvg.svg" />
              </div>
            </div>
            <form id="loginform" @submit.prevent="unsubscribe()">
              <p class="login-username">
                <label for="user_login"
                  style="margin-top: 20px; margin-bottom: 20px; text-align: center;color: #fff;">هل تريد الغاء اشتراكك
                  من منصة تعلم اللغة الإنجليزية</label>
              </p>
              <p class="login-submit">
                <button class="button button-primary"
                  style="width: 100%; background: linear-gradient(0deg,#077b7b 0,#03acac ); color: rgb(255, 255, 255);"
                  :disabled="isLoading">
                  <span v-if="isLoading" class="spinner"></span> <!-- Show spinner when loading -->
                  <span v-else>الغاء الاشتراك</span> <!-- Show text when not loading -->
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import config from '@/Api/config';
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { ref } from 'vue';
export default {
  name: 'AppUnsubscribe',
  setup() {
    const toast = useToast();
    const router = useRouter();
    const cookie = useCookie();
    const isLoading = ref(false);

    const unsubscribe = async () => {
      isLoading.value = true;

      try {
        const response = await HTTPDSP.get(`DSPUnsubscribe.php?msisdn=${cookie.getCookie("msisdn")}`, {
          headers: {
            Authorization: config.authHeader,
          },
        });

        if (response.data?.status == 0) {
          toast.error("انت لست مشترك في منصة تعلم اللغة الإنجليزية");
          // Remove all cookies
          cookie.keys().forEach((key) => cookie.removeCookie(key));
          // Redirect to the homepage
          router.push('/');
        } else if (response.data?.status == 1) {
          toast.success("تم الغاء اشتراكك من منصة تعلم اللغة الإنجليزية");
          // Remove all cookies
          cookie.keys().forEach((key) => cookie.removeCookie(key));
          // Redirect to the homepage
          router.push('/');
        } else {
          toast.success("تم الغاء اشتراكك من منصة تعلم اللغة الإنجليزية");
          // Remove all cookies
          cookie.keys().forEach((key) => cookie.removeCookie(key));
          // Redirect to the homepage
          router.push('/');
        }
      } catch (error) {
        console.error("Error during unsubscription:", error);

        if (error.response) {
          // Handle server errors
          const errorMsg = error.response.data?.message || "حدث خطأ في الخادم. حاول مرة أخرى لاحقًا.";
          toast.error(`Error: ${errorMsg}`);
        } else if (error.request) {
          // Handle network errors
          toast.error("خطأ في الشبكة: تعذر الوصول إلى الخادم. يرجى التحقق من الاتصال.");
        } else {
          // Handle unexpected errors
          toast.error("خطأ غير متوقع أثناء محاولة إلغاء الاشتراك.");
        }
      } finally {
        isLoading.value = false; // Ensure the loading state is reset
      }
    };


    //   const unsubscribe = async () => {
    //     isLoading.value = true

    //     try {

    //         await HTTPDSP.get(`DSPUnsubscribe.php?msisdn=${cookie.getCookie("msisdn")}`, {
    //             headers: {
    //                 'Authorization': config.authHeader,
    //             },
    //         }).then((res) => {
    //             if (res.data.status == 0) {
    //                 toast.error("انت لست مشترك في منصة تعلم اللغة الإنجليزية");
    //                 cookie.keys().forEach(cookie => cookie.removeCookie(cookie))
    //                 router.push('/')
    //             } else {
    //                 toast.success("تم الغاء اشتراكك من منصة تعلم اللغة الإنجليزية");
    //                 cookie.keys().forEach(cookie => cookie.removeCookie(cookie))
    //                 router.push('/')
    //             }
    //         }).catch((error) => {
    //             console.log(error);
    //         }).finally(() => {
    //             isLoading.value = false
    //         });

    //     } catch (error) {
    //         console.log(error);
    //     }
    //   }

    return {
      isLoading,
      unsubscribe
    }
  }
}
</script>

<style scoped>
.col-8 {
  max-width: 45%;
}

/* Style for the button */
.loading-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spinner style */
.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #028A8A;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

span {
  display: table;
  margin: 0 auto;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>