<template>
<div class="slick-list draggable" style="padding-bottom: 20px;">
  <div class="slick-track">
    <!-- Responsive Skeleton Loader -->
    <div 
      class="vueper-slides no-shadow skeleton-carousel" 
      style="display: flex; gap: 10px; overflow: hidden; padding: 10px; flex-wrap: nowrap;"
    >
    <div class="col-12 col-sm-4 col-lg-3 col-xl-3" v-for="n in 4" :key="n">
      <!-- Skeleton cards -->
      <div 
        
        class="skeleton-card" 
        style="flex: 1 1 calc(25% - 10px); height: 230px; background-color: #f0f0f0; border-radius: 10px; overflow: hidden; display: flex; flex-direction: column; align-items: center; justify-content: center;"
      >
        <!-- Skeleton image -->
        <div 
          class="skeleton-image" 
          style="width: 100%; height: 100%; background-color: #e0e0e0; border-radius: 10px 10px 0 0;"
        ></div>
        <!-- Skeleton title -->
        <div 
          class="skeleton-text" 
          style="width: 80%; height: 20px; margin-top: 10px; background-color: #d6d6d6; border-radius: 5px; margin-bottom: 10px;"
        ></div>
      </div>
    </div>
    </div>
  </div>
</div>
</template>
  
  <script>
  export default {
    name: "SkeletonPopularLoader",
  };
  </script>
  
  <style scoped>
   .skeleton-image,
  .skeleton-text {
    animation: pulse 1.5s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #d6d6d6;
    }
    100% {
      background-color: #e0e0e0;
    }
  }

  /* Default: 4 cards visible */
  .skeleton-card {
    flex: 1 1 calc(25% - 10px); /* 4 cards in a row */
  }

  /* Medium screens: 2 cards visible */
  @media (max-width: 1024px) {
    .skeleton-card {
      flex: 1 1 calc(50% - 10px); /* 2 cards in a row */
    }
  }

  /* Small screens (Phones): 1 card visible */
  @media (max-width: 768px) {
    .skeleton-card {
      flex: 1 1 calc(100% - 10px); /* Full-width, 1 card per row */
    }
    .skeleton-carousel {
      flex-wrap: wrap; /* Cards stack vertically */
    }
  }
</style>