<template>
  <div class="main-container" style="padding-right:0;padding-left:0" oncontextmenu="return false;">
    <section class="bg-gray pt-4 pr-15 pl-15">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-8 col-md-7" style="padding-right: 10px;padding-left: 10px;" v-for="publish in content"
            :key="publish.id">
            <template v-if="VideoLoading">
              <plyr-skeleton-loader />
            </template>
            <template v-else>
              <div tabindex="0"
                class="plyr plyr--full-ui plyr--video plyr--html5 plyr--fullscreen-enabled plyr--paused plyr--stopped plyr__poster-enabled">
                <div class="plyr__video-wrapper">
                  <video class="plyr" autoplay style="margin-right: 0px;width: 100%;" controls loop
                    controlsList="nodownload">
                    <source :src="publish.source" type="Video/mp4" size="720">
                  </video>
                </div>
              </div>
              <ul class="video-meta">
                <li><i class="fas fa-eye"></i> Views {{ totalView }} </li>
                <li><i class="fas fa-tags"></i> {{ publish.cp_name }} </li>
                <li><i class="fas fa-calendar-alt"></i> {{ publish.year }} </li>
                <li><i class="fas fa-film"></i> {{ publish.title_ar }}</li>
              </ul>
            </template>
            <div class="container-fluid">
              <div class="row mt-15 mb-15">
                <div class="col-sm-6 my-auto">
                  <div class="video-like">
                    <a @click="addLike" title="Like" class="mr-4 like-button"><i
                        :class="['fas', 'fa-thumbs-up', { active: isLiked }]"></i> <span
                        style="display: contents !important;">{{
                          totalLikes }}</span></a>
                    <a href="" title="Dislike" class="mr-4 dislike-button"><i class="fas fa-thumbs-down"></i> <span
                        style="display: contents !important;">0</span></a>
                  </div>
                </div>
                <div class="col-sm-6 my-auto">
                  <div class="video-shere"></div>
                </div>
              </div>
            </div>

            <div class="d-flex content-creator justify-content-between"></div>
            <div id="comments" class="comments-area">
              <h4 class="comments-title"> {{ getCommentslength }} comments </h4>
              <ul class="comment-list">
                <li class="comment even thread-even depth-1 parent" id="comment-6" itemscope=""
                  v-for="comment in getComments" :key="comment.id">
                  <div class="row">
                    <div class="col-md-2 col-xs-3 text-left text-md-center">
                      <img class="rounded-circle" alt="images" src="../../assets/images/pngegg.png">
                    </div>
                    <div class="col-md-10 col-xs-9">
                      <div class="commenter" v-if="!comment.full_name == ''">
                        {{ comment.full_name }} <span style="margin-left: 5px;"> {{ comment.comment_date.substring(0,
                          10)
                          }} -
                        </span>
                      </div>
                      <div class="commenter" v-else>
                        anonymous <span style="margin-left: 5px;">{{ comment.comment_date.substring(0, 10) }}</span>
                      </div>
                      <p>{{ comment.comment_text }} </p>
                      <!-- <a rel="nofollow" class="comment-reply-login" href="#">Log in to Reply</a> -->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="pb-100">
              <div id="comments" class="comments-area">
                <div id="respond" class="comment-respond">
                  <h3 id="reply-title" class="comment-reply-title">Leave a Comments</h3>
                  <form @submit.prevent="addComment" class="comment-form row">
                    <!-- <p class="logged-in-as">
                                            <a href="#" aria-label="Logged in as admin. Edit your profile.">Logged in as admin</a>.<a href="#">Log out?</a>
                                        </p> -->
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-12">
                          <textarea v-model="newComment" placeholder="Type your comment...."
                            aria-required="true"></textarea>
                        </div>
                        <div class="col-lg-12">
                          <button type="submit" :disabled="isLoading">
                            <span v-if="isLoading" class="spinner"></span> <!-- Show spinner when loading -->
                            <span v-else>Post Comment</span> <!-- Show text when not loading -->
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-5" style="padding-right: 0;padding-left: 0;">
            <div id="viewtube_related_videos-2" class="widget widget-video widget_viewtube_related_videos">
              <template v-if="sidebarLoading">
                <skeleton-sidebar />
              </template>
              <template v-else>
                <ul class="sidebar-related-video">
                  <li v-for="publish in CategoryContent" :key="publish.id" style="box-shadow: 0px 2px 1px #7d76766b">
                    <div class="related-video-thumb pointer">
                      <a @click="GoToContent(publish)">
                        <img v-lazy="publish.carsoul_img" alt="Image">
                      </a>
                    </div>
                    <div class="related-video-content pointer">
                      <p><a @click="GoToContent(publish)">{{ publish.title_ar }}</a></p>
                      <ul>
                        <li>
                          <a class="author" @click="GoToContent(publish)"> أ / {{ publish.cp_name }} </a>
                        </li>
                        <li>
                          <div class="d-flex video-meta-bottom">
                            . {{ publish.views }} Views
                          </div>
                        </li>
                        <li>
                          <div class="d-flex video-meta-bottom">
                            {{ publish.upload_date.substring(0, 10) }} </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router";
import { useCookie } from 'vue-cookie-next';
import { useToast } from "vue-toastification";
import config from '@/Api/config';
import { onMounted } from 'vue';
import PlyrSkeletonLoader from '../Spinners/PlyrSkeletonLoader.vue';
import SkeletonSidebar from '../Spinners/SkeletonSidebar.vue';
export default {
  components: { PlyrSkeletonLoader, SkeletonSidebar },
  name: 'AppContent',
  // props :{
  //     content_id:String,
  // },
  props: {
    content_id: {
      type: [Number, String],
      required: true, // Ensure the prop is passed
    },
  },
  async setup(props) {
    const router = useRouter();
    const cookie = useCookie();
    const toast = useToast();

    const CategoryContent = ref([]);
    const content = ref([]);
    const title = ref('');
    const date = ref('');
    const newComment = ref('');
    const getComments = ref([]);
    const getCommentslength = ref(0);
    const isLoading = ref(false);
    const totalLikes = ref(0);
    const totalView = ref(0);
    const isLiked = ref(false); // Track whether the icon is liked
    const VideoLoading = ref(false);
    const sidebarLoading = ref(false);

    const username = cookie.getCookie("msisdn"); // Replace with dynamic username if needed

    const interactionType = 'like';

    // Fetch Video from API
    // const fetchVideo = async () => {

    //   VideoLoading.value = true;

    //   try {

    //     const response = await HTTP.get(`ContentById.php?CID=${props.content_id}`, {
    //       headers: { 'Authorization': config.authHeader, },
    //     });

    //     content.value = response.data.Content || [];
    //     // console.log(getComments.value.length )

    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   } finally {
    //     VideoLoading.value = false;  // Set loading to false when fetching is complete
    //   }
    // }

    // Fetch Video from API
    const fetchVideo = async () => {
      VideoLoading.value = true;

      try {
        const response = await HTTP.get(`ContentById.php?CID=${props.content_id}`, {
          headers: { Authorization: config.authHeader },
        });

        // Check if the response contains the expected data
        if (response.data && response.data.Content) {
          content.value = response.data.Content;
        } else {
          console.warn("No content found in the response");
          content.value = [];
        }
      } catch (error) {
        // Handle specific error scenarios
        if (error.response) {
          // The request was made, and the server responded with a status code outside the 2xx range
          console.error("Server error:", error.response);
          console.error(
            `Error: Unable to fetch video content. Status: ${error.response.status} - ${error.response.statusText}`
          );
        } else if (error.request) {
          // The request was made, but no response was received
          console.error("No response received from the server:", error.request);
          console.error("Network error: Unable to fetch video content. Please check your internet connection.");
        } else {
          // Something else caused the error
          console.error("Unexpected error:", error.message);
          console.error("Unexpected error occurred while fetching video content.");
        }

        // Fallback to an empty array in case of an error
        content.value = [];
      } finally {
        VideoLoading.value = false; // Ensure loading flag is cleared
      }
    };


    // Fetch Category from the API
    const fetchCategory = async () => {
      sidebarLoading.value = true;

      try {
        const response = await HTTP.get(`GetContentByCategory.php?LIMIT=10&OFFSET=0&CID=${props.content_id}`, {
          headers: { Authorization: config.authHeader },
        });

        // Validate the response
        if (response.data && response.data.Content) {
          CategoryContent.value = response.data.Content;
        } else {
          console.warn("No content found in the response");
          CategoryContent.value = []; // Assign an empty array if no content is found
        }
      } catch (error) {
        // Handle specific error scenarios
        if (error.response) {
          // Server responded with a status code outside the 2xx range
          console.error("Server error:", error.response);
          console.error(
            `Error: Unable to fetch category content. Status: ${error.response.status} - ${error.response.statusText}`
          );
        } else if (error.request) {
          // Request was made but no response was received
          console.error("No response received from the server:", error.request);
          console.error("Network error: Unable to fetch category content. Please check your internet connection.");
        } else {
          // Error occurred during request setup
          console.error("Unexpected error:", error.message);
          console.error("Unexpected error occurred while fetching category content.");
        }

        // Fallback to an empty array in case of an error
        CategoryContent.value = [];
      } finally {
        // Ensure loading state is cleared
        sidebarLoading.value = false;
      }
    };


    // Fetch total likes from the API
    const fetchTotalLikes = async () => {
      try {
        const response = await HTTP.get(`GetInteractionbyContentId.php?content_id=${props.content_id}`, {
          headers: { Authorization: config.authHeader },
        });

        // Validate response and extract data
        if (response.data && Array.isArray(response.data.Interaction)) {
          const interactionData = response.data.Interaction;

          // Find "like" and "view" interactions
          const likeInteraction = interactionData.find(
            (interaction) => interaction.interaction_type === "like"
          );

          const viewInteraction = interactionData.find(
            (interaction) => interaction.interaction_type === "view"
          );

          // Safely assign values
          totalLikes.value = likeInteraction ? likeInteraction.interaction_count : 0;
          totalView.value = viewInteraction ? viewInteraction.interaction_count : 0;
          isLiked.value = false; // Update based on API response if applicable
        } else {
          console.warn("Unexpected response format or missing data");
          totalLikes.value = 0;
          totalView.value = 0;
        }
      } catch (error) {
        // Handle specific error cases
        if (error.response) {
          // Server responded with a status code outside the 2xx range
          console.error("Server error:", error.response);
          console.error(
            `Error: Unable to fetch interaction data. Status: ${error.response.status} - ${error.response.statusText}`
          );
        } else if (error.request) {
          // Request was made but no response was received
          console.error("No response received from the server:", error.request);
          console.error("Network error: Unable to fetch interaction data. Please check your connection.");
        } else {
          // Error occurred during request setup
          console.error("Unexpected error:", error.message);
          console.error("Unexpected error occurred while fetching interaction data.");
        }

        // Fallback values in case of an error
        totalLikes.value = 0;
        totalView.value = 0;
      }
    };


    // Add a like via the API
    const addLike = async () => {
      const contentId = props.content_id;

      try {
        if (isLiked.value) {
          // If already liked, optionally call an "unlike" API if supported
          console.log("Unlike API can be called here if supported.");
          isLiked.value = false;
          totalLikes.value = Math.max(totalLikes.value - 1, 0); // Avoid negative total likes
        } else {
          await HTTP.get(
            `AddUserInteraction.php?username=${encodeURIComponent(username)}&content_id=${encodeURIComponent(contentId)}&interaction_type=${encodeURIComponent(interactionType)}`,
            {
              headers: { Authorization: config.authHeader },
            }
          );
          await fetchTotalLikes(); // Refresh likes count
          isLiked.value = true; // Update UI state
        }
      } catch (error) {
        console.error("Error adding like:", error);

        if (error.response) {
          console.error(`Error: Unable to process your like. Server responded with: ${error.response.statusText}`);
        } else if (error.request) {
          console.error("Network error: Unable to process your like. Please check your connection.");
        } else {
          console.error("Unexpected error occurred while adding a like.");
        }
      }
    };

    // Fetch Comments from API
    const fetchComments = async () => {
      try {
        const response = await HTTP.get(
          `getCommentsByContentId.php?content_id=${encodeURIComponent(props.content_id)}`,
          {
            headers: { Authorization: config.authHeader },
          }
        );

        if (response.data && Array.isArray(response.data.Comments)) {
          const data = response.data.Comments;

          // Sort comments by oldest first
          getComments.value = data.sort((a, b) => new Date(a.comment_date) - new Date(b.comment_date));
          getCommentslength.value = data.length;
        } else {
          console.warn("No comments found in the response.");
          getComments.value = [];
          getCommentslength.value = 0;
        }
      } catch (error) {
        console.error("Error fetching comments:", error);

        if (error.response) {
          console.error(`Error: Unable to fetch comments. Server responded with: ${error.response.statusText}`);
        } else if (error.request) {
          console.error("Network error: Unable to fetch comments. Please check your connection.");
        } else {
          console.error("Unexpected error occurred while fetching comments.");
        }

        // Fallback to empty values
        getComments.value = [];
        getCommentslength.value = 0;
      }
    };


    // Add a comment via the API
    const addComment = async () => {
      if (!newComment.value.trim()) {
        toast.warning("Comment text cannot be empty!");
        return;
      }

      isLoading.value = true;

      try {
        const contentId = props.content_id;
        const commentText = newComment.value.trim();

        const response = await HTTP.get(
          `AddComment.php?username=${encodeURIComponent(username)}&content_id=${encodeURIComponent(contentId)}&comment_text=${encodeURIComponent(commentText)}`,
          {
            headers: { Authorization: config.authHeader },
          }
        );

        if (response.data && response.data.error_code === 0) {
          newComment.value = ''; // Clear the input field
          await fetchComments(); // Refresh comments
        } else {
          console.warn("Error adding comment:", response.data?.message || "Unknown error");
          toast.error("Failed to add comment. Please try again.");
        }
      } catch (error) {
        console.error("Error adding comment:", error);

        if (error.response) {
          toast.error(`Error: Unable to add comment. Server responded with: ${error.response.statusText}`);
        } else if (error.request) {
          toast.error("Network error: Unable to add comment. Please check your connection.");
        } else {
          toast.error("Unexpected error occurred while adding comment.");
        }
      } finally {
        isLoading.value = false;
      }
    };


    // onMounted(fetchTotalLikes);
    await fetchTotalLikes();
    await fetchComments();
    await fetchVideo();
    await fetchCategory();
    // onMounted(() => {

    const GoToContent = (publish) => {
      // const contentId = props.content_id; // Replace with dynamic content ID if needed
      const contentId = props.content_id; // Ensure it's a number

      try {
        HTTP.get(`AddPopularityScoreAndInteraction.php?username=${username}&content_id=${contentId}`, {
          headers: {
            'Authorization': config.authHeader,
          },

        });

      } catch (error) {
        console.error("Error adding like:", error);
      }

      router.push({ name: "Contents", params: { content_id: publish.id } });
    };



    return {
      CategoryContent,
      content,
      GoToContent,
      addComment,
      newComment,
      getComments,
      isLoading,
      title,
      date,
      totalLikes,
      totalView,
      addLike,
      getCommentslength,
      isLiked,
      VideoLoading,
      sidebarLoading,
    };

  },

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

/* .iframe-scale {
    width: 100vw;
height: 88vh;
}
@media(max-width:786px){
    .iframe-scale {
    height: 45vh;
}
} */


.fa-thumbs-up {
  cursor: pointer;
  color: gray;
  transition: color 0.3s ease;
}

.fa-thumbs-up.active {
  color: #004d4d;
  /* Change to your desired color */
}


.title {
  font-family: 'Zain_Regular', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 5px;
  transition: 0.5s;
  display: block;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
  background-color: #042f72;
  background-image: linear-gradient(62deg, #047072 0%, #009ac9 100%);
  padding: 8px 5px 5px 5px;
  border-radius: 12px;


}

.catalog2 {
  position: relative;
  padding: 5px 0 25px;
  padding-top: calc(50vh - 90%);
  padding-right: 0px;
  padding-bottom: 25px;
  padding-left: 0px;
  overflow: hidden;
  padding-bottom: calc(50vh - 60%);
}

.catalog__paginator-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px auto 0;
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background-color: #151f30;
  background-color: #047072;
  background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
}

.inner-plyr {
  margin-right: 0;
  width: 100%;
}

.view {
  margin-left: 0;
  padding: 0 !important;
  z-index: 98;
  top: 0px;
}


@media (min-width: 1200px) {
  .plyr {
    margin-right: 22px;
  }

  .video-meta {
    margin-right: 22px;
  }
}


@media (max-width: 768px) {

  .inner-plyr,
  .view {
    margin-right: 0;
    width: 100%;
  }

  .video-meta {
    margin-right: 0;
  }

  .view {
    margin-left: 0;
    padding: 0 !important;
    z-index: 98;
    top: 0px;
    margin-right: 0;
  }

  .plyr {
    margin-top: 50px;
  }
}

@media (max-width: 768px) and (min-width:412px) {


  .view {

    margin-right: -4%;
  }

}

@media (max-width: 400px) {

  .inner-plyr,
  .view {
    margin-right: 0;
    width: 100%;
  }

  .video-meta {
    margin-right: 0;
  }

  .view {
    margin-left: 0;
    padding: 0 !important;
    z-index: 98;
    top: 0px;
    margin-right: -4%;
  }

  .plyr {
    margin-top: 50px !important;
    margin-right: -2px !important;
  }
}

@media (max-width: 900px) and (min-width: 768px) {


  .plyr {
    margin-top: 90px !important;
  }

  .view {

    margin-right: 0;
  }
}

.plyr {
  font-family: 'Zain_Regular', sans-serif;
  font-weight: 400;
  border-radius: 0px;
  margin-top: 22px;
}

.card__cover::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 75%, rgba(0, 0, 0, 0.9) 100%);
  opacity: 0.1;
  transition: 0.5s;
}


/* Style for the button */
.loading-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spinner style */
.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #028A8A;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

span {
  display: table;
  margin: 0 auto;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>