<template>
    <div class="main-container" dir="ltr">
        <div class="row" dir="rtl">
            <div class="col-xl-6 col-md-6">
                <div class="my-account-content2" dir="rtl"
                    style="background: linear-gradient(0deg,#004d4d 0,#03acac );">
                    <div class="header-profile-login">
                        <div class="row justify-content-between">
                            <div class="col-8 col-sm-8 col-lg-8 col-xl-8">
                                <h6 class="text-center">تعلم اللغة الإنجليزية</h6>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4 col-xl-4" style="padding-right: 0;">
                                <img style="width:130px;float:left;" class="logo"
                                    src="../../assets/images/logoSvg.svg" />
                            </div>
                        </div>
                        <form id="loginform" @submit.prevent="Subscrib()">
                            <p class="login-username">
                                <label for="user_login"
                                    style="margin-top: 20px; margin-bottom: 20px; text-align: center;color: #fff;">
                                    ستوفر الخدمة للمشتركين الكلمات والعبارات والتعابير والمحادثات الأكثر أستخداماً والتي
                                    يتم أستخدامها كثيراً في الحياة اليومية , حتى يتمكنوا من التحدث باللغة الإنجليزية
                                    بطلاقة وبطريقة طبيعية وبثقة.
                                </label>
                            </p>
                            <p class="login-submit">
                                <button class="button button-primary"
                                    style="width: 100%; background: linear-gradient(0deg,#077b7b 0,#03acac ); color: rgb(255, 255, 255);">
                                    <span class="px-1 arabickufi">إشتراك</span> &nbsp;
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppSubscribes',
    setup() {
        const Subscrib = () => {
            window.open("https://dsplp.sd.zain.com/?p=2847968194", "_self");
        }
        return { Subscrib }
    }
}
</script>

<style></style>