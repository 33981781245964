<template>
 <div class="col-12 col-sm-4 col-lg-3 col-xl-3" v-for="n in 4" :key="n">
  <div class="card skeleton-card">
    <!-- Skeleton Image -->
    <div class="card__cover skeleton-image"></div>

    <!-- Skeleton Content -->
    <div class="video-content">
      <div class="d-flex">
        <div class="my-auto" style="width: 100%;">
          <!-- Skeleton Title -->
          <div class="skeleton-title"></div>

          <!-- Skeleton Subtitle -->
          <ul class="list-inline" style="justify-content: center; margin: 0; padding: 0;">
            <li class="list-inline-item skeleton-subtitle"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
  </template>
  
  <script>
  export default {
    name: "SkeletonLoaderMain",
  };
  </script>
  
<style scoped>
  /* General Skeleton Animation */
  .skeleton-card .skeleton-image,
  .skeleton-card .skeleton-title,
  .skeleton-card .skeleton-subtitle {
    animation: skeleton-loading 1.5s infinite ease-in-out;
    background-color: #e0e0e0;
  }

  /* Skeleton Image Placeholder */
  .skeleton-image {
    width: 100%;
    height: 280px; /* Adjust based on your card image size */
    border-radius: 10px;
  }

  /* Skeleton Title Placeholder */
  .skeleton-title {
    width: 70%;
    height: 20px;
    margin: 10px auto;
    border-radius: 5px;
    background-color: #d6d6d6;
  }

  /* Skeleton Subtitle Placeholder */
  .skeleton-subtitle {
    width: 50%;
    height: 15px;
    margin: 5px auto;
    border-radius: 5px;
    background-color: #d6d6d6;
  }

  /* Skeleton Loading Animation */
  @keyframes skeleton-loading {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
</style>