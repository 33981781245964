import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia';
import store from './store'
import fastclick from 'fastclick'
import lazyPlugin from 'vue3-lazy'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import Notifications from '@kyvg/vue3-notification'
import { VueCookieNext } from 'vue-cookie-next' 
window.$ = window.jQuery = require('jquery');

// main.js (or another script)
const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };
  
  loadScript('/static/js/plugins.js')
    .then(() => loadScript('/static/js/main.js'))
    .catch((err) => console.error('Error loading scripts:', err));
  

// import './assets/css/default.css';
// import './assets/css/plugins.css';
// import './assets/css/style.css';
// import './assets/css/color.css';
// import './assets/css/cld-video-player.css';
// import './assets/style.css';
// import './assets/responsive.css';

fastclick.attach(document.body)
const loadimage = require('./assets/images/loading.jpeg')
const app = createApp(App);
    app.use(store);
    app.use(router);
    router.app = app;
    app.use(createPinia());
    app.use(lazyPlugin, {
        preLoad: 1.3,
        loading: loadimage,
        attempt: 1
    })
    app.use(Toast, {
        transition: "Vue-Toastification__fade",
        maxToasts: 30,
        newestOnTop: true,
        timeout: 4000,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: true,
        rtl: true
    })
    app.use(Notifications)
    app.use(VueCookieNext);
    app.mount('#app');