<template>
   <div class="main-container" style="padding-right:0;padding-left:0" dir="ltr">
    <section class="bg-gray pt-4 pr-15 pl-15" style="background: #fff;">
      <div class="container-fluid" dir="rtl">
        <template v-if="entryLoading">
          <skeleton-entry-content/>
        </template>
        <template v-else>
        <div class="row justify-content-center" >
          <div class="col-xl-12 col-md-7" v-for="publish in CourseInfo" :key="publish.id">
              <img style="display: none" class="viewtube-player-single" :src="publish.img" />
                <div class="d-flex content-creator justify-content-between" dir="rtl">
                  <div class="my-auto">
                    <a >
                      <img :src="publish.img" alt=""></a>
                      <span class="pl-10">  {{publish.name_ar}}</span>
                  </div>
                </div>
                <div class="entry-content">
                  <h1 class="video-entry-title">{{publish.name_ar}}</h1>
                  <p>{{publish.name_en}}</p>
                </div>
          </div>
        </div>
      </template>
        <div class="row justify-content-center " dir="rtl"  data-slick='{"slidesToShow": , "slidesToScroll": }'>
          <div class="col-12">
            <h4 style="margin-top: 5px;"> كل الفيديوهات</h4>
          </div>
           <!-- Show loading spinner or text when data is being fetched -->
          <template v-if="loading">
            <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="n in 12" :key="n">
              <skeleton-loader />
            </div>
          </template>
          <template v-else>

          <div  class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="publish in paginatedItems" :key="publish.id">
            <div class="card">
              <div class="video-item-card">
                <a @click="GoToSeriesContent(publish)">
                  <div class="card__cover">
                    <img v-lazy="publish.img" class="custom-logo" alt="">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>          
                  </div>
                </a>
                <div class="video-content">
                  <div class="d-flex">
                    <div class="my-auto" style="width: 100%;">
                      <a @click="GoToSeriesContent(publish)">
                        <h5>{{publish.title_ar}}</h5>
                      </a>
                      <ul class="list-inline" style="justify-content: center;">
                        <li class="list-inline-item">
                          <div class="d-flex video-meta-bottom"> {{ publish.views }} Views <i class="fas fa-circle ml-2 mr-2"></i>{{ publish.year }} </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>                
          </div>
        </template>

          <div class="col-xl-12">
            <nav class="navigation pagination">
              <h2 class="screen-reader-text">Posts navigation</h2>
              <div class="nav-links">
                <button class="next page-numbers" @click="goToPage(page - 1)"  :disabled="page === 1">Previous </button>
                  <span aria-current="page" class="page-numbers current">Page {{ page }}</span>
                <button class="next page-numbers" @click="goToPage(page + 1)" :disabled="noMoreItems">Next ❯</button>
              </div>
            </nav>
          </div>

      </div>
    </div>
  </section>
   </div>
</template>

<script>
import config from '@/Api/config';
import { HTTP } from '@/Api/http-common';
import { computed, ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { onMounted } from 'vue';
import SkeletonLoader from '../Spinners/SkeletonLoader.vue';
import SkeletonEntryContent from '../Spinners/SkeletonEntryContent.vue';
export default {
  components: { SkeletonLoader, SkeletonEntryContent },
  props: {
    cat_id: {
      type: [Number, String],
      required: true, // Ensure the prop is passed
    },
  },
  name: 'AppSpeaking',
  async setup(props) {
    const router = useRouter();
    const cookie = useCookie();
    const All = ref([]);
    const CourseInfo = ref([]);

    const items = ref([]);  // Store items for the current page
    const page = ref(1);  // Current page number
    const itemsPerPage = 12;  // Number of items per page
    const noMoreItems = ref(false);  // Flag to check if there are no more items
    const entryLoading = ref(false);  // Loading state
    const loading = ref(false);  // Loading state
    
    const username = cookie.getCookie("msisdn"); // Replace with dynamic username if needed
    

    const fetchItems = async () => {

      loading.value = true;  // Set loading to true when fetching data

      try {
        const LIMIT = itemsPerPage;
        const OFFSET = (page.value - 1) * itemsPerPage;
        const cat_id = props.cat_id
         
        const response = await HTTP.get('GetCategoryContent.php', {
          params: {
            LIMIT,
            OFFSET,
            cat_id
          },
          headers: {'Authorization': config.authHeader,},
        });

        // Assuming the API returns items array in the response
        if (response.data.Content && response.data.Content.length) {
          items.value = response.data.Content;  // Store fetched items
          // If fewer items are returned than the limit, it indicates the last page
          noMoreItems.value = response.data.Content.length < itemsPerPage;
        }
      } catch (error) {

        console.error("Error fetching data:", error);
        items.value = [];

      } finally {
        loading.value = false;  // Set loading to false when fetching is complete
      }
    };

    const goToPage = (newPage) => {
      if (newPage >= 1 && !noMoreItems.value) {
        page.value = newPage;
        fetchItems();  // Fetch data for the new page
      }
    };

    const fetchCourse = async () => {

      entryLoading.value = true;

      try {
      
        const response = await HTTP.get(`GetCourse.php?ID=${props.cat_id}`,{
          headers: { 'Authorization': config.authHeader, },
        })

        if (response.data && response.data.CourseInfo) {
          CourseInfo.value = response.data.CourseInfo; 
        } else {
          console.warn('No content found in the response');
        }

      } catch (err) {
        console.error('Error fetching Course content:', err);
        CourseInfo.value = [];
      } finally {
        entryLoading.value = false;  // Set loading to false when fetching is complete
      }
    }

    onMounted(() => {
      fetchItems();  // Fetch initial data when the component mounts
      fetchCourse();
    });

    // Computed value for paginated items to be displayed in the view
    const paginatedItems = computed(() => items.value);
    


    const GoToSeriesContent = (publish) => {
      try {
          HTTP.get(`AddPopularityScoreAndInteraction.php?username=${username}&content_id=${publish.id}`, {
            headers: { 'Authorization': config.authHeader, },

          });

      } catch (error) {
          console.error("Error adding like:", error);
      }
        router.push({ name: "Contents", params: { content_id: publish.id } });
    };
    return { All ,CourseInfo, GoToSeriesContent,
      items,
      page,
      paginatedItems,
      noMoreItems,
      loading,
      entryLoading,
      goToPage,
    };
    
  },
}
</script>

<style>
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>