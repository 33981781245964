<template>
  <footer class="iq-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="player row">
            <div class="details col-6 col-sm-4 col-md-4 col-lg-3">
              <div class="now-playing">Now Playing:</div>
              <img :src="currentTrack.img" class="track-art" :alt="currentTrack.title_ar" />
              <div>
                <div class="track-name">{{ currentTrack.title_ar }}</div>
                <div class="track-artist">{{ currentTrack.name_ar }}</div>
              </div>
            </div>
            <div class="details col-6 col-sm-2 col-md-2 col-lg-2">
              <div class="pointer" @click="toggleRepeat" style="padding: 20px 50px;">
                <i class="fa fa-retweet" v-if="!isRepeat"></i>
                <i class="fa fa-retweet" style="color: #fff;" v-else></i>
              </div>
              <div class="pointer" @click="toggleLiked">
                <i class="fa fa-heart" v-if="!isLiked"></i>
                <i class="fa fa-heart" style="color: #F44336;" v-else></i>
              </div>
            </div>
            <div class="slider_container slider_music col-sm-5 col-md-4 col-lg-3">
              <div class="current-time">{{ currentTime }}</div>
              <div class="progress" @click="seek" ref="progress">
                <div class="progress-bar bg-success" role="progressbar" :style="{ width: progress + '%' }"></div>
              </div>
              <div class="total-duration">{{ totalDuration }}</div>
            </div>
            <div class="buttons col-6 col-sm-3 col-md-2 col-lg-2">
              <div class="prev-track" @click="previous"><i class="fa fa-step-backward fa-2x"></i></div>
              <div class="playpause-track">
                <i class="fa fa-play-circle fa-3x" v-if="!isPlaying" @click="play"></i>
                <i class="fa fa-pause-circle fa-3x" v-else @click="pause"></i>
              </div>
              <div class="next-track" @click="next"><i class="fa fa-step-forward fa-2x"></i></div>
            </div>
            <div class="slider_container sound col-sm-6 col-md-2 col-lg-2">
              <i class="fa fa-volume-down"></i>
              <input type="range" class="volume_slider" v-model="volume" min="0" max="1" step="0.01"
                @input="changeVolume" />
              <i class="fa fa-volume-up"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue';
import { HTTP } from '@/Api/http-common';
import config from '@/Api/config';
import { useCookie } from 'vue-cookie-next';
export default {
  props: {
    tracks: {
      type: Array,
      required: true
    },
    currentIndex: {
      type: Number,
      required: true
    }
  },

  setup(props, { emit }) { // Destructure `emit` from the context

    const cookie = useCookie();
    const audio = ref(new Audio()); // Initialize audio instance
    const isPlaying = ref(false);
    const volume = ref(1);
    const currentTime = ref(0);
    const totalDuration = ref(0);
    const progress = ref(0);
    const isRepeat = ref(false); // New state for repeat
    const isLiked = ref(false);  // Track like status for the current track

    const currentTrack = computed(() => props.tracks[props.currentIndex] || {});

    const play = () => {
      if (audio.value) {
        audio.value.play().then(() => {
          isPlaying.value = true;
        }).catch((err) => {
          console.error('Playback error:', err.message);
        });
      }
    };

    const pause = () => {
      if (audio.value) {
        audio.value.pause();
        isPlaying.value = false;
      }
    };

    const seek = (event) => {
      const percent = event.offsetX / event.target.offsetWidth;
      if (audio.value) {
        audio.value.currentTime = percent * audio.value.duration;
      }
    };

    const next = () => {
      const nextIndex = (props.currentIndex + 1) % props.tracks.length;
      emit('updateCurrentIndex', nextIndex); // Update current index in the parent component
    };

    const previous = () => {
      const prevIndex = (props.currentIndex - 1 + props.tracks.length) % props.tracks.length;
      emit('updateCurrentIndex', prevIndex); // Update current index in the parent component
    };

    const updateProgress = () => {
      if (audio.value) {
        progress.value = (audio.value.currentTime / audio.value.duration) * 100;
        currentTime.value = formatTime(audio.value.currentTime);
      }
    };

    const changeVolume = () => {
      if (audio.value) {
        audio.value.volume = volume.value;
      }
    };

    const formatTime = (secs) => {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Update total duration when track metadata is loaded
    const updateTotalDuration = () => {
      totalDuration.value = formatTime(audio.value.duration || 0);
    };


    const loadTrack = () => {
      if (audio.value) {
        audio.value.pause(); // Pause current track
        audio.value.currentTime = 0; // Reset current time
      }

      const track = currentTrack.value;
      if (track && track.source) {
        audio.value.src = track.source; // Set the new track source
        audio.value.load(); // Load the new track
        play(); // Automatically play the track
      }
    };

    const toggleRepeat = () => {
      isRepeat.value = !isRepeat.value;
    };


    const toggleLiked = async () => {
      const username = cookie.getCookie("msisdn"); // Provided username
      const contentId = currentTrack.value.id ; // Assuming track id exists, otherwise fallback to 406
      const interactionType = isLiked.value ? 'unlike' : 'like'; // Toggle between like and unlike

      try {
        const response = await HTTP.get(
            `AddUserInteraction.php?username=${encodeURIComponent(username)}&content_id=${encodeURIComponent(contentId)}&interaction_type=${encodeURIComponent(interactionType)}`,
            {
              headers: { Authorization: config.authHeader },
            }
          );
        // const response = await HTTP.get(url);
        const data = await response.data;

        if (data.error_code === 0) {
          isLiked.value = !isLiked.value; // Toggle like status on success
        } else if (data.error_code === 1) {
          isLiked.value = !isLiked.value;
        } else {
          console.error('Failed to interact with the content:', data.message);
        }
      } catch (error) {
        console.error('Error while interacting with the content:', error.message);
        // console.error("Error adding like:", error);
        if (error.response) {
          alert(`Error: Unable to process your like. Server responded with: ${error.response.statusText}`);
        } else if (error.request) {
          alert("Network error: Unable to process your like. Please check your connection.");
        } else {
          alert("Unexpected error occurred while adding a like.");
        }
      }
    };


    watch(() => props.currentIndex, () => {
      loadTrack(); // Load new track when index changes
    });


    onMounted(() => {
      audio.value.addEventListener('timeupdate', updateProgress);
      audio.value.addEventListener('ended', () => {
        if (isRepeat.value) {
          audio.value.currentTime = 0; // Repeat the current track
          audio.value.play();
        } else {
          next(); // Go to the next track
        }
      });
      audio.value.addEventListener('loadedmetadata', updateTotalDuration);
      loadTrack();
    });


    return {
      audio,
      isPlaying,
      volume,
      currentTime,
      progress,
      currentTrack,
      play,
      pause,
      seek,
      next,
      previous,
      changeVolume,
      totalDuration,
      updateTotalDuration,
      toggleRepeat, // Return toggleRepeat function to use in template
      isRepeat, // Return the repeat state to use in template
      toggleLiked,  // Return toggleLiked function
      isLiked,      // Return isLiked status

    };
  }
};
</script>

<style scoped>
.progress {
  min-width: 250px;
  height: 8px;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  /* background: rgba(0,0,0,0.06) */
}

.progress-bar {
  opacity: 0.8;
}

.seek_slider,
.volume_slider {
  background: #fff;
}

.harry {
  color: red;
}
</style>