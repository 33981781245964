<template>
    <nav class="navigation pagination">
      <h2 class="screen-reader-text">Posts navigation</h2>
      <div class="nav-links">
        <button class="next page-numbers" @click="changePage(page - 1)" :disabled="page === 1">Previous </button>
        <span aria-current="page" class="page-numbers current">Page {{ page }}</span>
        <button class="next page-numbers" @click="changePage(page + 1)" :disabled="noMoreItems">Next ❯</button>
      </div>
    </nav>
  <!-- </div> -->
</template>

<script>
export default {
  name: "Pagination",
  props: {
    page: Number,
    noMoreItems: Boolean,
  },
  emits: ["change-page"],
  setup(props, { emit }) {
    const changePage = (newPage) => {
      emit("change-page", newPage);
    };
    return {
      changePage,
    };
  },
};
</script>
