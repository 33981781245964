<template>
  <app-header />
  <div class="container-fluid p-0">
    <div class="d-flex topspace-90" dir="rtl">
      <app-side-bar />
      <Suspense>
        <template #default>
          <app-contacts />
        </template>
        <template #fallback>
          <app-loading />
        </template>
      </Suspense>
    </div>
  </div>
  <app-footer />
</template>

<script>
import AppFooter from "../../components/layout/AppFooter.vue";
import AppHeader from "../../components/layout/AppHeader.vue";
import AppSideBar from "../../components/layout/AppSideBar.vue";
import AppLoading from "../../components/Spinners/AppLoading.vue";
import { defineAsyncComponent, Suspense } from "vue";
const AppContacts = defineAsyncComponent(() =>
  import("../../components/Pages/AppContacts.vue")
);
export default {
  name: "Contacts",
  components: {
    AppHeader,
    AppSideBar,
    AppFooter,
    AppContacts,
    AppLoading,
    Suspense,
  },
};
</script>

<style>
</style>