<template>
  <div class="main-container" style="padding-right:0;padding-left:0" dir="ltr">
    <section class="bg-gray pt-4 pr-15 pl-15"
      style="background: rgb(255, 255, 255) none repeat scroll 0% 0%;margin-bottom: 15vh;">
      <div class="container-fluid" dir="rtl">
        <SkeletonSeries v-if="entryLoading" />
        <div class="row justify-content-center" v-else>
          <div class="col-xl-12 col-md-7" v-for="publish in CourseInfo" :key="publish.id">
            <img style="display: none" class="viewtube-player-single" :src="publish.img" />
            <div class="d-flex content-creator justify-content-between" dir="rtl">
              <div class="my-auto">
                <a href="#">
                  <img v-lazy="publish.img" alt=""></a>
                <span class="pl-10"> {{ publish.name_ar }}</span>
              </div>
              <div class="my-auto" style="color: #fff;">
                <!-- <a class="viewtube-btn subscribe" v-if="!pauseTrack" @click="play()">
                  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" viewBox="0 0 485 485"
                    style="width: 17px;height: 17px;margin-left: 10px;enable-background:new 0 0 277.338 277.338;"
                    xml:space="preserve" fill="#fff" version="1.1">
                    <g>
                      <path
                        d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5   s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026   C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5   S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z" />
                      <polygon points="181.062,336.575 343.938,242.5 181.062,148.425  " />
                    </g>
                  </svg> تشغيل</a>
                <a class="viewtube-btn subscribe" v-else @click="pause()">
                  <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                    y="0px" width="277.338px" height="277.338px" viewBox="0 0 277.338 277.338"
                    style="width: 17px;height: 17px;margin-left: 10px;enable-background:new 0 0 277.338 277.338;"
                    xml:space="preserve" fill="#fff" version="1.1">
                    <g>
                      <path
                        d="M14.22,45.665v186.013c0,25.223,16.711,45.66,37.327,45.66c20.618,0,37.339-20.438,37.339-45.66V45.665   c0-25.211-16.721-45.657-37.339-45.657C30.931,0,14.22,20.454,14.22,45.665z" />
                      <path
                        d="M225.78,0c-20.614,0-37.325,20.446-37.325,45.657V231.67c0,25.223,16.711,45.652,37.325,45.652s37.338-20.43,37.338-45.652   V45.665C263.109,20.454,246.394,0,225.78,0z" />
                    </g>
                  </svg> ايقاف</a> -->
              </div>
            </div>
            <div class="entry-content">
              <h1 class="video-entry-title">{{ publish.name_ar }}</h1>
              <p>{{ publish.name_en }}</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center " dir="rtl" data-slick='{"slidesToShow": , "slidesToScroll": }'>
          <div class="col-12">
            <h4 style="margin-top: 5px;"> كل الملفات الصوتية</h4>
          </div>

          <!-- Show loading spinner or text when data is being fetched -->
          <template v-if="contentLoading">
            <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="n in 12" :key="n">
              <skeleton-loader />
            </div>
          </template>
          <template v-else>
            <template v-if="paginatedItems.length">
              <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="(track, index) in paginatedItems" :key="track.id"
              @click.prevent="selectTrack(index)">
                <div class="card">
                  <div class="video-item-card">
                    <a @click="AddPopularity(track)">
                      <div class="card__cover">
                        <img v-lazy="track.img" class="custom-logo" alt="">
                        <!-- <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px" y="0px" width="277.338px" height="277.338px" viewBox="0 0 277.338 277.338"
                          style="width: 40px;height: 40px;margin-left: 10px;enable-background:new 0 0 277.338 277.338;"
                          xml:space="preserve" fill="#fff" version="1.1">
                          <g>
                            <path
                              d="M14.22,45.665v186.013c0,25.223,16.711,45.66,37.327,45.66c20.618,0,37.339-20.438,37.339-45.66V45.665   c0-25.211-16.721-45.657-37.339-45.657C30.931,0,14.22,20.454,14.22,45.665z" />
                            <path
                              d="M225.78,0c-20.614,0-37.325,20.446-37.325,45.657V231.67c0,25.223,16.711,45.652,37.325,45.652s37.338-20.43,37.338-45.652   V45.665C263.109,20.454,246.394,0,225.78,0z" />
                          </g>
                        </svg> -->
                        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px" y="0px" viewBox="0 0 485 485"
                          style="width: 40px;height: 40px;margin-left: 10px;enable-background:new 0 0 277.338 277.338;"
                          xml:space="preserve" fill="#fff" version="1.1">
                          <g>
                            <path
                              d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5   s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026   C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5   S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z" />
                            <polygon points="181.062,336.575 343.938,242.5 181.062,148.425  " />
                          </g>
                        </svg>
                      </div>
                    </a>
                    <div class="video-content">
                      <div class="d-flex">
                        <div class="my-auto " style="width: 100%;">
                          <a href="#">
                            <h5>{{ track.title_ar }}</h5>
                          </a>
                          <ul class="list-inline" style="justify-content: center;">
                            <!-- <li class="list-inline-item">
                              <a class="author ">{{ track.name_ar }}</a>
                            </li> -->
                            <li class="list-inline-item">
                              <div class="d-flex video-meta-bottom"> {{ track.views }} Views <i
                                  class="fas fa-circle ml-2 mr-2"></i>{{ track.year }} </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>

          <div class="col-xl-12">
            <Pagination :page="page" :noMoreItems="noMoreItems" @change-page="goToPage" />
          </div>
        </div>
      </div>
    </section>
    <keep-alive>
      <AudioPlayer :tracks="paginatedItems" :currentIndex="currentIndex" @updateCurrentIndex="updateCurrentIndex" :currentTrack="paginatedItems[currentIndex]" />
    </keep-alive>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import Pagination from "./Pagination.vue";
import AudioPlayer from "./AudioPlayer.vue";
import { HTTP } from "@/Api/http-common";
import config from "@/Api/config";
import SkeletonLoader from "../Spinners/SkeletonLoader.vue";
import SkeletonSeries from "../Spinners/SkeletonSeries.vue";
import { useCookie } from 'vue-cookie-next';
import { useNotification } from "@kyvg/vue3-notification";
export default {
  name: "AppSeriesContent",
  components: { SkeletonLoader, Pagination, AudioPlayer, SkeletonSeries },
  props: {
    series_id: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const cookie = useCookie();
    const { notify } = useNotification();

    const entryLoading = ref(false);
    const contentLoading = ref(false);
    const CourseInfo = ref({});
    const audios = ref([]);
    const page = ref(1);
    const itemsPerPage = 12;
    const noMoreItems = ref(false);
    const selectedAudio = ref(null); // Store selected audio details
    const currentIndex = ref(0);

    const paginatedItems = computed(() => audios.value);

    const fetchCourseContent = async () => {
      entryLoading.value = true;
      try {
        const response = await HTTP.get(`GetCourse.php?ID=${props.series_id}`, {
          headers: { Authorization: config.authHeader },
        });
        // CourseInfo.value = response.data.CourseInfo || {};

        // Check if response contains the expected data structure
        if (response.data && response.data.CourseInfo) {
          CourseInfo.value = response.data.CourseInfo;
        } else {
          throw new Error('Course is missing in the response');
        }

      } catch (error) {
        // console.error("Error fetching Course Info:", err);

        // Handle different types of errors
        if (error.response) {
          // The request was made and the server responded with a status code outside the range 2xx
          console.error('Server responded with an error:', error.response);
          // Optionally, display a user-friendly message
          notify({ group: "auth", title: 'Error: Unable to fetch course information. Please try again later.', });
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received from the server:', error.request);
          notify({ group: "auth", title: 'Error: No response from the server. Please check your network connection.', });
        } else {
          // Something happened in setting up the request that triggered an error
          console.error('Error setting up the request:', error.message);
          notify({ group: "auth", title: 'Error: Something went wrong while fetching course data.', });
        }

        // Fallback to an empty array in case of an error
        CourseInfo.value = [];

      } finally {
        entryLoading.value = false;
      }
    };

    const fetchAudios = async () => {
      contentLoading.value = true;

      try {

        const LIMIT = itemsPerPage;
        const OFFSET = (page.value - 1) * itemsPerPage;
        const cat_id = props.series_id

        const response = await HTTP.get("GetCategoryContent.php", {
          params: {
            LIMIT,
            OFFSET,
            cat_id
          },
          headers: { Authorization: config.authHeader },
        });

        // Check if the response contains valid data
        if (response.data && response.data.Content) {
          audios.value = response.data.Content;
          // If fewer items are returned than the limit, it indicates the last page
          noMoreItems.value = response.data.Content.length < itemsPerPage;
          // noMoreItems.value = audios.value.length < itemsPerPage;
        } else {
          console.warn("No content found in the response");
          audios.value = [];
        }
      } catch (error) {
        // Handle specific error types
        if (error.response) {
          // The request was made and the server responded with a status code outside the 2xx range
          console.error("Server error:", error.response);
          notify({ group: "auth", title: 'Error: Unable to fetch audios. Please try again later.', });
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
          notify({ group: "auth", title: 'Network error: No response from the server. Please check your internet connection.', });
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error setting up the request:", error.message);
          notify({ group: "auth", title: 'Unexpected error:', });
        }

        // Fallback to an empty array in case of an error
        audios.value = [];
      } finally {
        contentLoading.value = false;
      }
    };


    // const selectTrack = (index) => {
    //   currentIndex.value = index;  // Update the current track index
    //   console.log('Selected track:', paginatedItems.value[index]); // Debugging

    // };

    const selectTrack = (index) => {
    currentIndex.value = index;  // Update the current track index
    const audio = document.querySelector("audio"); // Assuming one exists in DOM
    if (audio) {
        audio.src = paginatedItems.value[index].source; // Set new source
        audio.play(); // Start playback
    }
};

    const updateCurrentIndex = (index) => {
      currentIndex.value = index;

    };


    const AddPopularity = async (track) => {
      const username = cookie.getCookie("msisdn"); // Provided username
      const contentId = track.id; // Assuming track.id is the contentId
      try {
        // Send a GET request to the API
        const response = await HTTP.get(`AddPopularityScoreAndInteraction.php?username=${encodeURIComponent(username)}&content_id=${encodeURIComponent(contentId)}`, {
          headers: {
            'Authorization': config.authHeader,
          },
        });

        const data = await response.data;

        if (data.error_code === 0) {
          return;
        } else {
          console.error('Error adding popularity:', error);
        }

      } catch (error) {
        console.error('Error while Popularity added:', error.message);
        if (error.response) {
          notify({ group: "auth", title: `Error: Unable to process your Popularity added. Server responded with: ${error.response.statusText}`, });
        } else if (error.request) {
          notify({ group: "auth", title: 'Network error: Unable to process your Popularity added. Please check your connection.', });
        } else {
          notify({ group: "auth", title: 'Unexpected error occurred while adding a Popularity.', });
        }
      }
    };



    const goToPage = (newPage) => {
      if (newPage >= 1 && !noMoreItems.value) {
        page.value = newPage;
        fetchAudios();
      }
    };

    onMounted(() => {
      fetchCourseContent();
      fetchAudios();
    });

    return {
      CourseInfo,
      audios,
      paginatedItems,
      entryLoading,
      noMoreItems,
      contentLoading,
      page,
      selectedAudio,
      goToPage,
      selectTrack,
      updateCurrentIndex,
      currentIndex,
      AddPopularity,
    };
  },
};
</script>

<style>
.progress-bar {
  width: 100%;
  height: 10px;
  background-color: lightgray;
  cursor: pointer;
}

.harry {
  color: red;
}
</style>