<template>
    <app-header/>
        <div class="container-fluid p-0">
            <div class="d-flex topspace-90" dir="rtl">
                <app-side-bar/>
                <Suspense>
                    <template #default>
                        <app-series-content :series_id="series_id" />
                    </template>
                    <template #fallback>
                        <app-loading/>
                    </template>
                </Suspense>
            </div>
        </div>
    <!-- <app-footer/> -->
</template>

<script>
// import AppFooter from '../../components/layout/AppFooter.vue'
import AppHeader from '../../components/layout/AppHeader.vue'
import AppSideBar from '../../components/layout/AppSideBar.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { defineAsyncComponent, Suspense } from "vue";
const AppSeriesContent = defineAsyncComponent(() =>
  import("../../components/Pages/AppSeriesContent.vue")
);
export default {
    props: {
        series_id: {
            type: [Number, String],
            required: true, // Ensure the prop is passed
        },
    },
  components: { AppSeriesContent, AppHeader, AppSideBar ,Suspense , AppLoading},
}
</script>

<style>

</style>