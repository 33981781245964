<template>
   <div class="skeleton-video-card">
  <!-- Skeleton Video -->
  <div class="skeleton-video"></div>

  <!-- Skeleton Video Meta -->
  <!-- <ul class="skeleton-video-meta">
    <li class="skeleton-meta-item"></li>
    <li class="skeleton-meta-item"></li>
    <li class="skeleton-meta-item"></li>
    <li class="skeleton-meta-item"></li>
  </ul> -->

  <!-- Skeleton Buttons -->
  <div class="skeleton-buttons">
    <div class="skeleton-like"></div>
    <!-- <div class="skeleton-dislike"></div>
    <div class="skeleton-like"></div>
    <div class="skeleton-dislike"></div> -->
    
  </div>
</div>              
  
  </template>
  
  <script>
  export default {
    name: "PlyrSkeletonLoader",
  };
  </script>
  
<style scoped>
  /* Skeleton Card Styles */
  .skeleton-video-card {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Skeleton Video Placeholder */
  .skeleton-video {
    width: 100%;
    height: 350px;
    background-color: #e0e0e0;
    border-radius: 8px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
  }

  /* Skeleton Meta Placeholder */
  .skeleton-video-meta {
    list-style: none;
    padding: 0;
    margin: 20px 0 10px;
  }

  .skeleton-meta-item {
    height: 15px;
    margin: 10px 0;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
  }

  /* Skeleton Button Placeholder */
  .skeleton-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .skeleton-like,
  .skeleton-dislike {
    width: 100%;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
  }

  /* Skeleton Loading Animation */
  @keyframes skeleton-loading {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
</style>