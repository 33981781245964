<template>
  <app-header/>
        <div class="container-fluid p-0">
            <div class="d-flex topspace-90" dir="rtl">
                <app-side-bar/>
                <Suspense>
                    <template #default>
                        <app-speaking :cat_id='cat_id'/>
                    </template>
                    <template #fallback>
                        <app-loading/>
                    </template>
                </Suspense>
            </div>
        </div>
    <app-footer/>
</template>

<script>
import AppFooter from '../../components/layout/AppFooter.vue'
import AppHeader from '../../components/layout/AppHeader.vue'
import AppSideBar from '../../components/layout/AppSideBar.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { defineAsyncComponent, Suspense } from "vue";
const AppSpeaking = defineAsyncComponent(() =>
  import("../../components/Pages/AppSpeaking.vue")
);
export default {
    
    props: {
        cat_id: {
            type: [Number, String],
            required: true, // Ensure the prop is passed
        },
    },

    components: { AppHeader, AppSideBar, AppFooter, Suspense,AppLoading, AppSpeaking },
    // name: 'Speaking'
}
</script>

<style>

</style>