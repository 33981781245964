<template>
  <router-view :key="$route.fullPath"/>
  <!-- <notifications /> -->
  <notifications group="auth" position="top center" />
  <notifications group="app" position="bottom right" />
</template>

<style>

</style>
