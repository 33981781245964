<template>
  <div class="sidebar-menu">
    <nav class="desktop-menu">
      <template v-if="LoadingCat">
        <ul class="skeleton-menu">
          <!-- Skeleton Menu Item -->
          <li class="skeleton-menu-item menu-item" v-for="n in 8" :key="n">
            <div class="skeleton-icon"></div>
            <div class="skeleton-text"></div>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul class="menu">
          <li class="menu-item current-menu-item"><router-link to="/"><i class="fas fa-home"></i><span>الصفحة الرئيسية
              </span></router-link></li>

          <template v-for="publish in categor" :key="publish.id">
            <li class="menu-item pointer" v-if="publish.type == 1">
              <a @click.prevent="GoToSer(publish)">
                <i class="fas fa-microphone-alt"></i>
                <span>{{ publish.name_ar }}</span>
              </a>
            </li>
            <li class="menu-item pointer" v-else><a @click="GoToVideo(publish)"><i class="fab fa-youtube"></i><span>{{
              publish.name_ar }}</span></a></li>
          </template>


          <!-- <li class="menu-item"><router-link :to="{ name: 'SeriesContent', params: { series_id: 1 }}"><i class="fas fa-microphone-alt"></i><span>مفردات اللغة</span></router-link></li>
                <li class="menu-item"><router-link :to="{ name: 'SeriesContent', params: { series_id: 2 }}"><i class="fas fa-microphone-alt"></i><span>التعابير والعبارات</span></router-link></li>  -->
          <!-- <li class="menu-item"><router-link to="/Speaking"><i class="fab fa-youtube"></i><span>تحدث الإنجليزية</span></router-link></li> -->
          <template v-if="showHeader">
            <li class="menu-item pointer"><router-link to="/user-profile"><i class="fas fa-user-cog"></i><span>الملف
                  الشخصي </span></router-link></li>
            <li class="menu-item pointer"><router-link to="/contact-us"><i class="fas fa-address-card"></i><span>اتصل
                  بنا</span></router-link></li>
            <li class="menu-item pointer"><router-link to="/Unsubscribe"><i class="fas fa-tag"></i><span>إلغاء
                  الاشتراك</span></router-link></li>

          </template>
          <template v-else>
            <li class="menu-item pointer"><router-link to="/Login"><i class="fas fa-tag"></i><span>تسجيل
                  الدخول</span></router-link></li>
            <li class="menu-item pointer"><a @click="gotoDSP()"><i class="fas fa-tag"></i><span>إشتراك </span></a></li>
          </template>
        </ul>
      </template>
    </nav>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, watchEffect } from '@vue/runtime-core';
import { HTTPDSP } from '@/Api/http-dsp';
import { HTTP } from '@/Api/http-common';
import { useRoute, useRouter } from 'vue-router';
import { useCookie } from 'vue-cookie-next'
import config from '@/Api/config';
export default {
  name: 'AppSideBar',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const cookie = useCookie()

    const showHeader = ref(false);
    const search = ref([]);
    const categor = ref([]);
    const LoadingCat = ref(false);

    watchEffect(async () => {
      if (cookie.isCookieAvailable('msisdn')) {

        showHeader.value = true;

        try {
          const response = await HTTPDSP.get(`DSPCheckLogin.php?msisdn=${cookie.getCookie("msisdn")}`, {
            headers: { Authorization: config.authHeader },
          });

          const status = response.data?.status;
          const message = response.data?.message || "Unknown error";

          if (status == 1 && message == "Successful") {
            console.log("User is logged in and subscribed.");
            return { status: "subscribed" };

          } else if (status == 0 && message == "Not subscribed") {
            cookie.removeCookie('msisdn');
            cookie.removeCookie('status');
            cookie.removeCookie('minutes');
            return { status: "not_subscribed" };
          } else {
            console.warn("Unexpected status or message:", response.data);
            cookie.removeCookie('msisdn');
            cookie.removeCookie('status');
            cookie.removeCookie('minutes');
            return { status: "unexpected_status", message };
          }

        } catch (error) {
          console.error('Error checking subscription:', error);
        }
      } else {
        showHeader.value = false;
      }
    });


    const fetchCoursesInfo = async () => {
      LoadingCat.value = true;

      try {
        const response = await HTTP.get('GetAllCourses.php', {
          headers: { 'Authorization': config.authHeader },
        });

        // Check if the response contains the expected data structure
        if (response.data && response.data.Courses) {
          categor.value = response.data.Courses || [];
        } else {
          console.warn('No content found in the response');
        }

      } catch (error) {
        // Handle different types of errors
        console.error('Server error:', error.response);

        categor.value = [];
      } finally {
        LoadingCat.value = false;  // Set loading to false when fetching is complete
      }
    };

    onMounted(async () => {
      await fetchCoursesInfo();  // Fetch initial data when the component mounts
    });



    const GoToSer = (publish) => {
      router.push({ name: "SeriesContent", params: { series_id: publish.id } });
    };

    const GoToVideo = (publish) => {
      router.push({ name: "speaking", params: { cat_id: publish.id } });
    };

    const SearchData = () => {
      router.push({ name: "Searchs", params: { search_id: search.value } });
    }

    const gotoDSP = () => {
      window.open("https://dsplp.sd.zain.com/?p=2847968194", "_self");
      // router.push({ name: "Subscribe" });
    }

    return { search, categor, showHeader, SearchData, GoToSer, GoToVideo, gotoDSP, LoadingCat };
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

/* Skeleton Menu Styles */
.skeleton-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 80%;
}

.skeleton-menu-item {
  display: flex;
  align-items: center;
  padding: 27px 0;
  height: 40px;
}

/* Skeleton Icon Placeholder */
.skeleton-icon {
  width: 60px;
  height: 45px;
  background-color: #e0e0e0;
  border-radius: 50%;
  animation: skeleton-loading 1.5s infinite ease-in-out;
  margin-right: 10px;
}

/* Skeleton Text Placeholder */
.skeleton-text {
  flex: 1;
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 5px;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

/* Skeleton Loading Animation */
@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #f0f0f0;
  }

  100% {
    background-color: #e0e0e0;
  }
}
</style>