<template>
  <div class="main-container">
    <section class="bg-gray pt-4 pr-15 pl-15">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-8 col-md-7">
            <div class="pb-100">
              <div id="comments" class="comments-area">
                <div id="respond" class="comment-respond">
                  <h3 id="reply-title" class="comment-reply-title">Edit Profile</h3>
                    <form @submit.prevent="UpdateUserInfo" id="commentform" class="comment-form row">
                      <!-- <p class="logged-in-as">
                        <a href="#" aria-label="Logged in as admin. Edit your profile.">Logged in as admin</a>.<a href="#">Log out?</a>
                      </p> -->
                      <div class="container">
                        <div class="row">
                          <div class="col-xl-6">
                            <input type="text" id="fullname" v-model="fullname" placeholder="Full Name" required>
                          </div>
                          <div class="col-xl-6">
                            <input type="text" id="email" v-model="email" placeholder="Enter your email" required>
                          </div>
                          <div class="col-lg-12" style="top: 20px;">
                            <!-- <button type="submit">Edit</button> -->
                            <button class="button button-primary" style="width: 100%; background: linear-gradient(0deg,#077b7b 0,#03acac ); color: rgb(255, 255, 255);" :disabled="isLoading">
                                <span v-if="isLoading" class="spinner"></span> <!-- Show spinner when loading -->
                                <span v-else >Update </span> <!-- Show text when not loading -->
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-7">
            <div class="pb-100">
              <div id="comments" class="comments-area">
                <div id="respond" class="comment-respond">
                  <h3 id="reply-title" class="comment-reply-title">Profile info</h3>
                  <template v-if="LoadingUser">
                    <ul class="skeleton-list">
                    <!-- Skeleton List Items -->
                    <li class="skeleton-item" v-for="n in 5" :key="n"></li>
                  </ul>
                  </template>
                  <template v-else>
                  <ul v-for="user in getUsers" :key="user.id">
                    <li ><a>Phone Number: </a>{{ user.username }}</li>
                    <li><a >Full Name: {{ user.full_name }}</a></li>
                    <li><a >Email: {{ user.email }}</a></li>
                    <li><a >Join Date: {{ user.join_date.substring(0,10) }}</a></li>
                  </ul>
                </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { HTTP } from '@/Api/http-common';
import config from '@/Api/config';
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useCookie } from 'vue-cookie-next';
export default {
  async setup() {

    const fullname = ref("");
    const email = ref("");
    const isLoading = ref(false);
    const LoadingUser = ref(false);
    const getUsers = ref([]);

    const toast = useToast();
    const router = useRouter();
    const cookie = useCookie();
      
    const username = cookie.getCookie("msisdn"); // Replace with dynamic username if needed

    // Email validation regex
    // const validateEmail = (emailValue) => {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   return emailRegex.test(emailValue);
    // };

    // Regex for full name (supports spaces, hyphens, and apostrophes)
    const fullNameRegex = /^[a-zA-Z]+(?:[-'\s][a-zA-Z]+)+$/;

    // Regex for email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    

    // Fetch UserInfo from API
    const fetchUserInfo = async () => {

      LoadingUser.value = true;
    
    try {
        const response = await HTTP.get(`getUserInfo.php?username=${username}`, {
          headers: {
            'Authorization': config.authHeader,
          },
      });

      if (response.data && response.data.UserInfo) {
          getUsers.value = response.data.UserInfo	 || [];
      } else {
          console.warn('No content found in the response');
      }
     
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
       LoadingUser.value = false;  // Set loading to false when fetching is complete
    }
  };

    const UpdateUserInfo = async () => {
      

      if (!fullNameRegex.test(fullname.value)) {
        // error.value = "Please enter a valid full name.";
        toast.warning("Please enter a valid full name.");
      } else if (!emailRegex.test(email.value)) {
        toast.warning("Please enter a valid email address.");
        // error.value = "Please enter a valid email address.";
      } else {
        // success.value = "Both full name and email are valid!";

        isLoading.value = true
        
        try {
          
          const response = await HTTP.get(`UpdateUserInfo.php?username=${encodeURIComponent(username)}&full_name=${encodeURIComponent(fullname.value)}&email=${encodeURIComponent(email.value)}`, {
            headers: {'Authorization': config.authHeader,},
          });

          if (response.data.status === 1) {
            toast.success("Update profile is successful");
            fullname.value = '';
            email.value = '';
            fetchUserInfo();
          } else {
            toast.info("Update profile failed");
          }

        } catch (error) {
          console.error("Error updating user info:", error);
        } finally {
          isLoading.value = false;  // Set loading to false when fetching is complete
        }
      }
      
    }

    onMounted(() => {
      fetchUserInfo();  // Fetch initial data when the component mounts
    });

    return {
      fullname,
      email,
      isLoading,
      getUsers,
      LoadingUser,
      UpdateUserInfo,
    }
  }
};
</script>

<style scoped>
/* Style for the button */
.loading-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spinner style */
.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #028A8A;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
span {
    display: table;
    margin: 0 auto;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


 /* Skeleton List Styles */
 .skeleton-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .skeleton-item {
    height: 20px;
    margin-bottom: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
  }

  /* Skeleton Loading Animation */
  @keyframes skeleton-loading {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
</style>