<template>
    <section class="section-padding bg-gray">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-md-7">
                <div id="comments" class="comments-area">
                    <h4 class="comments-title">Contacts us</h4>
                    <div id="respond" class="comment-respond">
                        <h3 id="reply-title" class="comment-reply-title">
                            Leave a Contacts
                        </h3>
                        <form @submit.prevent="ContactsInfo" id="commentform" class="comment-form row">
                            <p class="logged-in-as">
                                <!-- <a href="#" aria-label="Logged in as admin. Edit your profile.">Logged in as
                                    admin</a>.<a href="#">Log out?</a> -->
                            </p>
                            <div class="container">
                                <div class="row">
                                    <!-- <div class="col-xl-6">
                                        <input type="text" placeholder="First Name" />
                                    </div> -->
                                    <div class="col-xl-12">
                                        <input type="text" v-model="title" placeholder="Title" />
                                    </div>
                                    <div class="col-xl-12">
                                        <textarea id="comment" v-model="description" placeholder="Type your Description...." aria-required="true"></textarea>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="submit" :disabled="isLoading">
                                            <span v-if="isLoading" class="spinner"></span>
                                            <!-- Show spinner when loading -->
                                            <span v-else>Add Contacts </span>
                                            <!-- Show text when not loading -->
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { computed, ref, watch } from "vue";
import { HTTP } from "@/Api/http-common";
import config from "@/Api/config";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useCookie } from "vue-cookie-next";
export default {
    name: "AppContacts",

    async setup() {
        const title = ref("");
        const description = ref("");
        const isLoading = ref(false);

        const toast = useToast();
        const router = useRouter();
        const cookie = useCookie();

        const username = cookie.getCookie("msisdn"); // Replace with dynamic username if needed

        // Regex rules
        const titleRegex = /^[a-zA-Z0-9\s]{3,50}$/; // Alphanumeric, 3-50 characters
        const descriptionRegex = /^.{5,100}$/; // Any characters, 5-100 characters

        // Validation rules
        const isTitleValid = computed(() => titleRegex.test(title.value.trim())); // Title must match the regex
        const isDescriptionValid = computed(() => descriptionRegex.test(description.value.trim())); // Description must match the regex

        const isFormValid = computed(() => isTitleValid.value && isDescriptionValid.value);

        const ContactsInfo = async () => {

            if (isFormValid.value) {
                console.log('Form is valid, submit the data');
                // Perform your form submission logic here

                isLoading.value = true;

                try {
                    const response = await HTTP.get(
                        `AddForum.php?username=${encodeURIComponent(
                            username
                        )}&title=${encodeURIComponent(
                            title.value
                        )}&description=${encodeURIComponent(description.value)}`,
                        {
                            headers: { Authorization: config.authHeader },
                        }
                    );

                    if (response.data.error_code == 0) {
                        toast.success("Contact successfully added!");
                        title.value = "";
                        description.value = "";
                    } else {
                        toast.info("Failed to add contact. Please try again.");
                    }
                } catch (error) {
                    console.error("Error adding contact:", error);

                    // Check the error response type (network, server, or unexpected error)
                    if (error.response) {
                        // Server responded with an error
                        const errorMsg = error.response.data?.message || "Server error, please try again later.";
                        toast.error(`Error: ${errorMsg}`);
                    } else if (error.request) {
                        // No response from the server (network error)
                        toast.error("Network error: Unable to reach the server. Please check your internet connection.");
                    } else {
                        // Something else went wrong
                        toast.error("Unexpected error occurred while adding contact.");
                    }
                } finally {
                    isLoading.value = false; // Set loading to false when fetching is complete
                }
            } else {
                console.error('Form is invalid');
                toast.warning("Form is invalid");
            }

        };


        return {
            title,
            description,
            isLoading,
            ContactsInfo,
            isTitleValid,
            isDescriptionValid,
            isFormValid,
        };
    },
};
</script>

<style scoped>
/* Spinner style */
.spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #028a8a;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

span {
    display: table;
    margin: 0 auto;
}

/* Spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>