<template>
 <div class="row justify-content-center">
  <div class="col-xl-12 col-md-7">
    <!-- Skeleton loader for the video player -->
    <!-- <div class="skeleton-image" style="width: 100%; height: 200px; background-color: #e0e0e0;"></div> -->

    <!-- Skeleton loader for the content creator section -->
    <div class="d-flex content-creator justify-content-between" dir="rtl" style="margin-top: 20px;">
      <div class="my-auto">
        <!-- Skeleton for the avatar -->
        <div class="skeleton-avatar" style="width: 40px; height: 40px; background-color: #e0e0e0; border-radius: 50%; display: inline-block;"></div>
        <!-- Skeleton for the name -->
        <span class="skeleton-text" style="margin-left: 10px; width: 130px; height: 15px; background-color: #e0e0e0; display: inline-block; border-radius: 10px 10px;"></span>
      </div>
      <!-- Skeleton for the buttons -->
      <div class="my-auto">
        <!-- <div class="skeleton-button" style="width: 70px; height: 30px; background-color: #e0e0e0; border-radius: 5px; display: inline-block; margin-left: 10px;"></div> -->
        <!-- <div class="skeleton-button" style="width: 70px; height: 30px; background-color: #e0e0e0; border-radius: 5px; display: inline-block;border-radius: 10px 10px;"></div> -->
      </div>
    </div>

    <!-- Skeleton loader for the entry content -->
    <div class="entry-content" style="margin-top: 20px;">
      <!-- Skeleton for the title -->
      <div class="skeleton-text" style="width: 250px; height: 25px; background-color: #e0e0e0;border-radius: 10px 10px;"></div>
      <!-- Skeleton for the paragraph -->
      <div class="skeleton-text" style="width: 100%; height: 2px; background-color: #e0e0e0; margin-top: 10px;"></div>
      <div class="skeleton-text" style="width: 25%; height: 15px; background-color: #e0e0e0; margin-top: 10px;border-radius: 10px 10px;"></div>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .skeleton-image,
  .skeleton-avatar,
  .skeleton-text,
  .skeleton-button {
    animation: pulse 1.5s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #d6d6d6;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
</style>