<template>  
<ul class="skeleton-sidebar-related-video">
  <!-- Skeleton Video Item -->
  <li class="skeleton-related-video-item" v-for="i in 10" :key="i">
    <!-- Skeleton Thumbnail -->
    <div class="skeleton-related-video-thumb"></div>

    <!-- Skeleton Content -->
    <div class="skeleton-related-video-content">
      <div class="skeleton-title"></div>
      <div class="skeleton-meta">
        <div class="skeleton-meta-line"></div>
        <div class="skeleton-meta-line"></div>
        <div class="skeleton-meta-line"></div>
      </div>
    </div>
  </li>
</ul>           
</template>
  
<script>
  export default {
    name: "SkeletonLoader",
  };
</script>
  
<style scoped>
.widget li::before {
  content: "";
  font-family: "font awesome 5 pro";
  font-weight: 900;
  top: 0;
  left: -17px;
  font-size: 8px;
  position: absolute;
  color: #ddd;
}
 /* Skeleton List Styles */
 .skeleton-sidebar-related-video {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .skeleton-related-video-item {
    display: flex;
    gap: 15px;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0px 2px 1px rgba(125, 118, 118, 0.42);
  }

  /* Skeleton Thumbnail */
  .skeleton-related-video-thumb {
    width: 150px;
    height: 130px;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
  }

  /* Skeleton Content */
  .skeleton-related-video-content {
    flex: 1;
  }

  .skeleton-title {
    width: 70%;
    height: 15px;
    margin-bottom: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
  }

  .skeleton-meta {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .skeleton-meta-line {
    width: 50%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
  }

  /* Skeleton Loading Animation */
  @keyframes skeleton-loading {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
</style>